import axios from 'axios';

const api_url = process.env.VUE_APP_ROOT_API + 'api/';

export default {
    name: 'Contents',
    get_content_slug(slug) {
        return axios.get(api_url + 'content/' + slug, { validateStatus: false });
    }
}
