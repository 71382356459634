<template>
    <HeaderHome/>
    <FiltroEvento/>
    <div class="container-fluid body">
        <div class="container">
            <div class="row body-box">
                <div class="col-md-8">
                    <h1>{{ contents.name }}</h1>
                    <div v-html="contents.description"></div>
                    <Atalhos/>
                </div>
                <div class="col-md-4">
                    <FacebookSide/>
                </div>
            </div>
        </div>
    </div>
    <Newsletter/>
    <Footer/>
    <FooterEnd/>
</template>

<script >
import Contents from '@/api/Contents';
import {defineComponent} from 'vue';
import HeaderHome from '../components/HeaderHome.vue'
import FiltroEvento from '../components/FiltroEvento.vue'
import Footer from '../components/Footer.vue'
import FooterEnd from '../components/FooterEnd.vue'
import Newsletter from '../components/Newsletter.vue'
import FacebookSide from '../components/FacebookSide'
import Atalhos from "@/components/Atalhos";
import router from "../router/router";

export default defineComponent({
    name: 'CadastreSeuEvento',
    components: {
        HeaderHome,
        FiltroEvento,
        Footer,
        FooterEnd,
        Atalhos,
        Newsletter,
        FacebookSide
    },
    data() {
        return {
            contents: {
                name: '',
                description: ''
            },
            slug: ''
        }
    },
    beforeMount() {
        this.slug = 'cadastre-seu-evento';
    },
    mounted() {
        var contents = Contents.get_content_slug(this.slug);

        contents.then((request) => {
            if (request.status == 200) {
                this.contents = request.data;
            } else {
                router.push('/conteudo/nao-encontrado/');
            }
        });
    }
});
</script>

<style>

</style>
